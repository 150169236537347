import twitterIcon from './twitter.svg';

export function Twitter(props) {
  return (
    <div className={props.className}>
      <a className="logo-nav" href="https://twitter.com/mackemify">
        <img width={'25px'} src={twitterIcon} alt="Twitter logo" />
      </a>
    </div>
  );
}
