const CleanTranslation = function (t) {
  t.translation = t.translation.trim();
  var tildeMatch = new RegExp('~', 'gi');
  if (tildeMatch.test(t.translation)) {
    t.translation = t.translation.replace(tildeMatch, '');
  }

  return t;
};

export default CleanTranslation;
