import foundationOfLightLogo from './foundation-of-light-logo.png';

export function FoundationOfLightLogo() {
  return (
    <a
      className="logo-nav"
      href="https://foundationoflight.co.uk/get-involved/make-a-donation/"
    >
      <img
        src={foundationOfLightLogo}
        className="good-causes-logo"
        alt="Sunderland Foundation of Light logo"
      />
    </a>
  );
}
