import foodbank from './foodbank-color.png';

export function FoodBankLogo() {
  return (
    <a className="logo-nav" href="https://sunderland.foodbank.org.uk/">
      <img
        src={foodbank}
        className="foodbank-logo"
        alt="Sunderland foodbank logo"
      />
    </a>
  );
}
