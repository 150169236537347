const phrases = {
  'a commotion broke out': [{ translation: 'there was hell on' }],
  'a ruckus broke out': [{ translation: 'there was hell on' }],
  'angry person': [{ translation: 'radgie' }],
  'boxer shorts': [{ translation: 'cowies' }],
  'coal fire': [{ translation: 'bleezer' }],
  'come on': [{ translation: "ha'way" }],
  'do not': [{ translation: 'dinnet' }],
  "don't": [{ translation: 'dinnet' }],
  "don't know": [{ translation: 'dinnar' }],
  'door latch': [{ translation: 'sneck' }],
  'drug addict': [{ translation: "bag'ead" }],
  'fat belly': [{ translation: 'kite' }],
  'fat stomach': [{ translation: 'kite' }],
  'feign illness': [{ translation: 'doll' }],
  'fish and chips': [{ translation: 'fish lot' }],
  'gate handle': [{ translation: 'sneck' }],
  'gate latch': [{ translation: 'sneck' }],
  'give over': [{ translation: 'giveower' }],
  'going to': [{ translation: 'ganner' }],
  'had an argument': [{ translation: 'had words' }],
  'half past': [{ translation: 'apast' }],
  'heavy defeat': [{ translation: 'scudden' }],
  'his brother': [{ translation: 'his boy' }],
  'his girlfriend': [{ translation: 'their lass' }],
  'his wife': [{ translation: 'their lass' }],
  'hissy fit': [{ translation: 'radge' }],
  'hot head': [{ translation: 'radgie' }],
  'i am': [{ translation: "a'm" }],
  "i don't believe you": [{ translation: 'haddaway' }],
  'i have': [{ translation: "a'v" }],
  'killed with laughter': [{ translation: 'decked' }],
  'latch handle': [{ translation: 'sneck' }],
  'leave it out': [{ translation: 'giveower' }],
  'look here': [{ translation: 'looker' }],
  'loose cannon': [{ translation: "mad'ead" }],
  'my girlfriend': [{ translation: 'ower lass' }],
  'my wife': [{ translation: 'ower lass' }],
  'no problem': [{ translation: 'nee bosh' }],
  'play truant': [{ translation: 'doll' }],
  'proved wrong': [{ translation: 'clamped' }],
  'punched to floor': [{ translation: 'decked' }],
  'quarter to': [{ translation: 'quar tee' }],
  'repeat offender': [{ translation: 'reet wrangen' }],
  'severe headache': [{ translation: "stotten 'eedache" }],
  'shut up': [{ translation: 'shurrup' }],
  'silly person': [{ translation: 'daftie' }],
  'the home': [{ translation: 'the kip' }],
  'the house': [{ translation: 'the kip' }],
  'there was a commotion': [{ translation: 'there was hell on' }],
  'there was a ruckus': [{ translation: 'there was hell on' }],
  'they kissed each other': [{ translation: 'they met each other' }],
  'very bad headache': [{ translation: "stottent 'eedache" }],
  'very cold': [{ translation: 'brassic' }],
  'very drunk': [{ translation: 'stotten' }],
  'want to': [{ translation: 'wanner' }, { translation: 'wurp' }],
  'will not': [{ translation: 'winnet' }, { translation: 'wint' }],
  'would like a pint': [{ translation: 'clammen for a pint' }],
  'would not': [{ translation: 'wadnt' }],
  'you two': [{ translation: 'yeyahs tee' }],
};

const words = {
  alone: [{ translation: 'alarne' }],
  along: [{ translation: 'alang' }],
  already: [{ translation: 'arlriddy' }],
  alright: [{ translation: 'alreet' }],
  amazing: [{ translation: 'ellish' }],
  anxious: [{ translation: 'chewed' }],
  anything: [{ translation: 'owt' }],
  arrow: [{ translation: 'arrer' }],
  awful: [{ translation: 'arful' }],
  baddie: [{ translation: 'wrangen' }],
  bald: [{ translation: 'barld' }],
  baldy: [{ translation: 'bardly' }],
  belly: [{ translation: 'kite' }],
  big: [{ translation: 'wass' }, { translation: 'massaye' }],
  birthday: [{ translation: 'birthder' }],
  blind: [{ translation: 'blinnd' }],
  blowing: [{ translation: 'blarn' }],
  boots: [{ translation: 'beuts' }],
  boy: [{ translation: 'laddo' }],
  brought: [{ translation: 'braart' }],
  brute: [{ translation: 'wrangen' }],
  'bus fare': [{ translation: 'bussy' }],
  "can't": [{ translation: 'cannet' }],
  cannot: [{ translation: 'cannet' }],
  cash: [{ translation: 'lowie' }],
  cast: [{ translation: 'chark' }],
  chalk: [{ translation: 'chark' }],
  chaps: [{ translation: 'lads' }],
  chav: [{ translation: 'charver' }],
  'chewing gum': [
    { translation: 'chewie' },
    { translation: 'chewy' },
    { translation: 'gowie' },
  ],
  child: [{ translation: 'bairn' }],
  children: [{ translation: 'bairn' }],
  chucked: [{ translation: 'hoyed' }],
  clever: [{ translation: 'clivver' }],
  clothes: [{ translation: 'clays' }],
  cold: [{ translation: 'card' }],
  computer: [{ translation: 'compewter' }],
  cookbook: [{ translation: 'cewkbewk' }],
  counterfeit: [{ translation: 'shnidy' }],
  crazy: [{ translation: 'acker' }],
  criminal: [{ translation: 'wrangen' }],
  current: [{ translation: 'cerrent' }],
  curry: [{ translation: 'cerry' }],
  dad: [{ translation: 'da' }],
  darts: [{ translation: 'arrers' }],
  dejected: [{ translation: 'wounded' }],
  dirty: [{ translation: 'hacky' }],
  disappointed: [{ translation: 'wounded' }],
  dishcloth: [{ translation: 'dishclout' }],
  dishevelled: [{ translation: 'a clip' }],
  dispondent: [{ translation: 'wounded' }],
  disproved: [{ translation: 'clamped' }],
  do: [{ translation: 'dee' }],
  'do not': [{ translation: 'dinnet' }],
  downcast: [{ translation: 'wounded' }],
  draws: [{ translation: 'draars' }],
  drunk: [{ translation: 'chemist' }],
  eight: [{ translation: 'ite' }],
  eighteen: [{ translation: "ite'een" }],
  eighty: [{ translation: 'itey' }],
  every: [{ translation: 'ivry' }],
  everybody: [{ translation: 'ivrybody' }],
  everyone: [{ translation: 'ivryone' }],
  'exactly!': [{ translation: 'strites' }],
  excellent: [{ translation: 'topper' }],
  fab: [{ translation: 'choss' }],
  fabulous: [{ translation: 'choss' }],
  face: [{ translation: 'piat' }, { translation: 'fiass' }],
  fake: [{ translation: 'shnidy' }],
  fatigued: [{ translation: 'paggered' }],
  fiend: [{ translation: 'wrangen' }],
  film: [{ translation: 'fillem' }],
  first: [{ translation: 'fust' }],
  follow: [{ translation: 'folly' }],
  food: [{ translation: 'pouch' }],
  football: [{ translation: 'footbarl' }],
  for: [{ translation: 'fer' }],
  forty: [{ translation: 'fotty' }],
  foul: [{ translation: 'fowel' }],
  fourteen: [{ translation: "fower'een" }],
  fowl: [{ translation: 'fowel' }],
  friend: [{ translation: 'marra' }],
  friends: [{ translation: 'marras' }],
  frozen: [{ translation: 'frozzen' }],
  'get in!': [{ translation: 'skiderp' }],
  ginormous: [{ translation: 'wass' }, { translation: 'massaye' }],
  god: [{ translation: 'Kevin Phillips' }, { translation: 'Peter Reid' }],
  good: [{ translation: 'canny' }],
  grandma: [{ translation: 'gannie' }],
  grandmother: [{ translation: 'gannie' }],
  great: [{ translation: 'ellish' }],
  ground: [{ translation: 'grund' }],
  gum: [{ translation: 'gowie' }],
  gut: [{ translation: 'kite' }],
  half: [{ translation: 'arf' }],
  haway: [{ translation: "ha'way" }],
  he: [{ translation: "'e" }],
  headache: [{ translation: 'bad head' }],
  hello: [{ translation: 'alreet' }],
  hi: [{ translation: 'alreet' }],
  his: [{ translation: "'is" }],
  hit: [{ translation: 'ploat' }, { translation: 'bray' }],
  hold: [{ translation: 'howld' }],
  hollow: [{ translation: 'holly' }],
  home: [{ translation: 'kip' }],
  honestly: [{ translation: 'strite up' }],
  horse: [{ translation: 'gallower' }],
  house: [{ translation: 'kip' }],
  huge: [{ translation: 'wass' }, { translation: 'massaye' }],
  hundred: [{ translation: 'undred' }],
  hurry: [{ translation: 'herry' }],
  hurt: [{ translation: 'knack' }],
  hurts: [{ translation: 'knacks' }],
  "i'm": [{ translation: "a'm" }],
  "i've": [{ translation: "a'v" }],
  icing: [{ translation: 'icenen' }],
  idiot: [{ translation: 'daftie' }],
  impatient: [{ translation: 'impitant' }],
  impetuous: [{ translation: 'impitant' }],
  jumper: [{ translation: 'ganzie' }],
  kid: [{ translation: 'bairn' }],
  knackered: [{ translation: 'paggered' }],
  knickers: [{ translation: 'cowies' }],
  know: [{ translation: 'knar' }],
  lad: [{ translation: 'laddo' }],
  large: [{ translation: 'wass' }, { translation: 'massaye' }],
  latch: [{ translation: 'sneck' }],
  loads: [{ translation: 'hods' }],
  lots: [{ translation: 'hods' }],
  lunch: [{ translation: 'bait' }],
  make: [{ translation: 'mack' }],
  man: [{ translation: 'gadgie' }],
  massive: [{ translation: 'wass' }, { translation: 'massaye' }],
  mate: [{ translation: 'marra' }],
  mates: [{ translation: 'marras' }],
  me: [{ translation: 'uz' }],
  medicine: [{ translation: 'medicin' }],
  migraine: [{ translation: "stotten 'eedache" }],
  mischief: [{ translation: 'caper' }],
  miscreant: [{ translation: 'charver' }],
  money: [{ translation: 'lowie' }],
  more: [{ translation: 'mare' }],
  mud: [{ translation: 'clarts' }],
  mum: [{ translation: 'mam' }],
  mummy: [{ translation: 'mammy' }],
  my: [{ translation: 'me' }],
  never: [{ translation: 'nivver' }],
  nice: [{ translation: 'canny' }],
  no: [{ translation: 'nee' }],
  nose: [{ translation: 'shneck' }],
  nothing: [{ translation: 'nowt' }],
  nowhere: [{ translation: 'neewhere' }],
  of: [{ translation: 'er' }],
  'of course!': [{ translation: 'why aye' }],
  offender: [{ translation: 'wrangen' }],
  old: [{ translation: 'owld' }],
  only: [{ translation: 'arnly' }],
  our: [{ translation: 'ower' }],
  over: [{ translation: 'ower' }],
  own: [{ translation: 'arn' }],
  pal: [{ translation: 'marra' }],
  phlegm: [{ translation: 'hockle' }],
  pig: [{ translation: 'gissy' }],
  plastic: [{ translation: 'placker' }],
  plenty: [{ translation: 'hods' }],
  poorly: [{ translation: 'pooley' }],
  potato: [{ translation: 'tatie' }],
  pound: [{ translation: 'pund' }],
  pregnant: [{ translation: 'babbied up' }],
  probably: [{ translation: 'problees' }],
  punch: [{ translation: 'clout' }],
  punched: [{ translation: 'clouted' }],
  rage: [{ translation: 'radgie' }],
  rascal: [{ translation: 'wrangen' }],
  ready: [{ translation: 'riddy' }],
  really: [{ translation: 'git' }],
  register: [{ translation: 'redchester' }],
  reprobate: [{ translation: 'wrangen' }],
  rogue: [{ translation: 'wrangen' }],
  roughian: [{ translation: 'charver' }],
  round: [{ translation: 'rund' }],
  same: [{ translation: 'sham' }],
  santa: [{ translation: 'santie' }],
  scallywag: [{ translation: 'wrangen' }],
  school: [{ translation: 'skewel' }],
  scoundrel: [{ translation: 'wrangen' }],
  seven: [{ translation: 'sivven' }],
  seventy: [{ translation: 'sivventy' }],
  she: [{ translation: 'sher' }],
  skim: [{ translation: 'scoit' }],
  skive: [{ translation: 'doll' }],
  sleep: [{ translation: 'kip' }],
  smells: [{ translation: 'liften' }],
  smelly: [{ translation: 'liften' }],
  snow: [{ translation: 'snar' }],
  snowball: [{ translation: 'snarbarl' }],
  soaked: [{ translation: 'drarked' }],
  soaking: [{ translation: 'drarked' }],
  something: [{ translation: 'summink' }],
  sopping: [{ translation: 'drarked' }],
  spit: [{ translation: 'hockle' }],
  spittle: [{ translation: 'hockle' }],
  splinter: [{ translation: 'spelk' }],
  sprint: [{ translation: 'pelt' }],
  state: [{ translation: 'stite' }],
  sticky: [{ translation: 'claggy' }],
  stinks: [{ translation: 'liften' }],
  stone: [{ translation: 'clem' }],
  stranger: [{ translation: 'laddo' }],
  stupid: [{ translation: 'stiuppid' }],
  sunderland: [{ translation: 'sunlun' }],
  super: [{ translation: 'topper' }],
  sweater: [{ translation: 'ganzie' }],
  sweets: [{ translation: 'ket' }],
  table: [{ translation: 'chabble' }],
  take: [{ translation: 'tack' }],
  talk: [{ translation: 'tark' }],
  teacher: [{ translation: 'tecker' }],
  testicles: [{ translation: 'clems' }],
  there: [{ translation: 'ther' }],
  thicko: [{ translation: 'dilutey' }],
  thirsty: [{ translation: 'clammen' }],
  thrashing: [{ translation: 'scudden' }],
  threw: [{ translation: 'hoyed' }],
  throw: [{ translation: 'hoy' }],
  tired: [{ translation: 'paggered' }, { translation: 'cabbaged' }],
  to: [{ translation: 'ter' }],
  together: [{ translation: 'tergether' }],
  told: [{ translation: 'telt' }],
  treated: [{ translation: 'tret' }],
  trousers: [{ translation: 'draars' }],
  underpants: [{ translation: 'cowies' }],
  underwear: [{ translation: 'cowies' }],
  unfair: [{ translation: 'shan' }],
  unwell: [{ translation: 'pooley' }],
  very: [{ translation: 'git' }, { translation: 'dead' }],
  villain: [{ translation: 'neckender' }],
  was: [{ translation: 'wesh' }],
  washington: [{ translation: 'washy' }],
  water: [{ translation: 'watter' }],
  we: [{ translation: 'wer' }],
  wearsider: [{ translation: 'mackem' }],
  whack: [{ translation: 'lamp' }],
  who: [{ translation: 'wee' }],
  woman: [{ translation: 'wifey' }, { translation: 'willick' }],
  "won't": [{ translation: 'winnet' }, { translation: 'wint' }],
  would: [{ translation: 'wad' }],
  "wouldn't": [{ translation: 'wadnt' }],
  wrong: [{ translation: 'wrang' }],
  yes: [{ translation: 'aye' }],
  you: [{ translation: 'yeyah' }],
  youngster: [{ translation: 'youngen' }],
  your: [{ translation: 'youer' }, { translation: 'yer' }],
};

export { words, phrases };
