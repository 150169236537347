import { FoodBankLogo } from './FoodBankLogo';
import { FoundationOfLightLogo } from './FoundationOfLightLogo';
import Header from './Header';
import { Twitter } from './Twitter';
import { Link } from 'react-router-dom';

import { words, phrases } from './lib/mackem/v2';

function dictionaryStats(words, phrases) {
  const wordCount = Object.keys(words).length;
  const phraseCount = Object.keys(phrases).length;

  return { wordCount, phraseCount };
}
function MoreInfo() {
  return (
    <div>
      <nav className="nav">
        <Twitter className="nav-item-left" />
        <Link className="nav-item" to="/">
          Home
        </Link>
      </nav>
      <div className="more-info">
        <div className="more-info-header">
          <Header />
        </div>
        <div className="more-info-section">
          <div className="about">
            <h2>About</h2>

            <DictionaryStats {...dictionaryStats(words, phrases)} />
          </div>
          <GoodLocalCauses />

          <div className="more-info-column-row">
            <Thanks />
            <AddToOurDictionary />
          </div>
        </div>
      </div>
    </div>
  );
}

function DictionaryStats(props) {
  const { wordCount, phraseCount } = props;
  return (
    <div className="about-text">
      <p className="about-text">
        Mackemify is a fun English ter Mackem translator. As er today ower
        dictionary, has <b>{wordCount}</b> words and <b>{phraseCount}</b>{' '}
        phrases. Ower dictionary is ever changing and sourced from various
        source materials, marras, family and people around Sunlun.
      </p>
    </div>
  );
}

function AddToOurDictionary() {
  return (
    <div className="add-to-our-dictionary">
      <h2>Keep it growing</h2>
      <p className="about-text">
        You can request additions to our dictionary at{' '}
        <a href="mailto:contact@mackemify.com">here</a>.
      </p>
    </div>
  );
}

function Thanks() {
  return (
    <div className="thanks">
      <h2>
        Say hello{' '}
        <span role="img" aria-label="wave emoji">
          👋
        </span>{' '}
      </h2>
      <Geordify />
      <MackemDictionary />
    </div>
  );
}

function GoodLocalCauses() {
  return (
    <>
      <h2>Give support</h2>

      <div className="good-local-causes">
        <FoodBankLogo />
        <FoundationOfLightLogo />
      </div>
    </>
  );
}
function MackemDictionary() {
  return (
    <div className="hello-nav" style={{ padding: '10px' }}>
      <a href="https://www.a-love-supreme.com/product-page/the-mackem-dictionary-paperback">
        The Mackem Dictionary
      </a>
    </div>
  );
}

function Geordify() {
  return (
    <div className="hello-nav" style={{ padding: '10px' }}>
      <a href="https://geordify.com/">Geordify</a>
    </div>
  );
}

export default MoreInfo;
