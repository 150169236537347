function WordTranslator(t) {
  if (!t) {
    return new Error('No translation object provided');
  }
  if (typeof t.message !== 'string') {
    return new Error('Nothing to translate');
  }
  if (typeof t.words !== 'object') {
    return new Error('No language or dialect provided');
  }

  Object.keys(t.words).forEach(function (word) {
    // Check if user message matches a word in the dictionary.
    // Ignore punctuation in the dictionary word and message for test condition
    var matchWord = new RegExp(
      '\\b' + word.replace(/[^\w\s]/, '') + '\\b',
      'gi'
    );
    const punctuationStrippedMessage = t.message.replace(/[^\w\s]/, '');

    if (matchWord.test(punctuationStrippedMessage)) {
      var choice = Math.floor(Math.random() * t.words[word].length);

      // Ignore punctuation from input (t.translation) by stripping it out of the translation text.
      // Then replace the match with the translation text from the word dictionary.
      // Use the original dictionary word (always has punctuation) to perform the word dictionary lookup.
      t.translation = t.translation
        .replace(/[^\w\s]/, '')
        .replace(matchWord, t.words[word][choice].translation);
      t.translations.push(t.words[word][choice]);
    }
  });

  return t;
}

export default WordTranslator;
