function PhraseTranslator(t) {
  if (!t) {
    return new Error('No translation object provided');
  }
  if (typeof t.message !== 'string') {
    return new Error('No message provided');
  }
  if (typeof t.phrases !== 'object') {
    return new Error('No phrases provided');
  }

  Object.keys(t.phrases).forEach(function (phrase) {
    // Check if user message matches a phrase in the dictionary.
    // Ignore punctuation in the dictionary phrase and message for test condition
    var phraseMatch = new RegExp(
      '\\b' + phrase.replace(/[^\w\s]/, '') + '\\b',
      'gi'
    );
    const punctuationStrippedMessage = t.message.replace(/[^\w\s]/, '');

    if (phraseMatch.test(punctuationStrippedMessage)) {
      var choice = Math.floor(Math.random() * t.phrases[phrase].length);

      // Ignore punctuation from input (t.translation) by stripping it out of the translation text.
      // Then replace the match with the translation text from the phrase dictionary.
      // Use the original dictionary phrase (always has punctuation) to perform the phrase dictionary lookup.
      t.translation = t.translation
        .replace(/[^\w\s]/, '')
        .replace(
          phraseMatch,
          t.phrases[phrase][choice].translation.split('').join('~')
        );

      t.translations.push(t.phrases[phrase][choice]);
    }
  });

  return t;
}

export default PhraseTranslator;
