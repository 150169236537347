import WordTranslator from "./word_translator";
import PhraseTranslator from "./phrase_translator";
import CleanTranslation from "./clean_translation";
import ReverseTranslation from "./reverse_translator";
import Translation from "./translation_object";
import prep from "./prep";
import pipe from "./pipe";

const Translator = function (message, words, phrases, reverse) {
  if (typeof message !== "string") {
    return new Error({ message: "Nothing to translate" });
  }

  if (typeof words !== "object") {
    return new Error({ message: "No language or dialect for words specified" });
  }

  if (typeof phrases !== "object") {
    return new Error({
      message: "No language or dialect for phrases specified",
    });
  }

  if (reverse) {
    phrases = ReverseTranslation(phrases);
    words = ReverseTranslation(words);
  }

  const translation = new Translation(message, words, phrases);

  const translate = pipe(
    prep,
    PhraseTranslator,
    WordTranslator,
    CleanTranslation
  );
  const result = translate(translation);
  return result;
};

export default Translator;
