import logo from './logo.png';

function Header() {
  return (
    <header className="header">
      <a className="logo-nav" href="/">
        <img
          src={logo}
          className="logo"
          alt="Mackemify - The Mackem Translator"
          title="Mackemify - The Mackem Translator"
        />
      </a>
    </header>
  );
}

export default Header;
