import swapArrows from './swap-arrows.svg';
import { useState } from 'react';
import './App.css';
import Translator from './lib/translator';
import { words, phrases } from './lib/mackem/v2';
import { useSearchParams, Link } from 'react-router-dom';
import Header from './Header';
import { FoodBankLogo } from './FoodBankLogo';
import { Twitter } from './Twitter';

function App() {
  return <Home />;
}

function Home() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [reverse, setreverse] = useState(
    searchParams.get('reverse') === 'true'
  );

  const [inputText, setInputText] = useState(searchParams.get('text') || '');
  const [translation, setTranslation] = useState(
    Translator(searchParams.get('text') || '', words, phrases, reverse)
      .translation
  );

  const handleTextChange = (event) => {
    event.preventDefault();

    setInputText(event.target.value);

    const result = Translator(event.target.value, words, phrases, reverse);

    setTranslation(result.translation);
    setSearchParams({
      text: event.target.value,
      reverse: reverse,
    });
  };

  const handleTranslationSwitch = (event) => {
    event.preventDefault();

    setreverse(!reverse);

    const result = Translator(translation, words, phrases, !reverse);

    setTranslation(result.translation);
    setInputText(result.message);
    setSearchParams({
      text: result.message,
      reverse: !reverse,
    });
  };

  return (
    <>
      <nav className="nav">
        <Twitter className="nav-item-left" />
        <Link className="nav-item" to="/more">
          More info
        </Link>
      </nav>

      <div className="container">
        <Header />

        <div className="translate-component">
          <div className="translate-header">
            <div className="translate-header-item">
              {reverse === true && (
                <label className={reverse && 'shake-text'}>Mackem</label>
              )}
              {reverse === false && (
                <label className={!reverse && 'shake-text'}>English</label>
              )}
            </div>
            <div className="translate-header-item">
              <img
                src={swapArrows}
                alt="Swap arrows"
                className="swap-arrows"
                onClick={handleTranslationSwitch}
              />
            </div>
            <div className="translate-header-item">
              <label>{reverse === true ? 'English' : 'Mackem'}</label>
            </div>
          </div>
          <textarea
            placeholder={`Type something in ${
              !reverse ? 'English' : 'Mackem'
            } here`}
            className="translate-text-area-input"
            id="translate-text-area-input"
            name="translate-text-area-input"
            onChange={handleTextChange}
            value={inputText}
          />
          <textarea
            placeholder="Translation will appear here"
            className="translate-text-area"
            value={translation}
            readOnly
          />
        </div>

        <footer className="footer">
          <FoodBankLogo />
          <a
            className="foodbank-link"
            href="https://sunderland.foodbank.org.uk/"
          >
            Learn More
          </a>
        </footer>
      </div>
    </>
  );
}

export default App;
